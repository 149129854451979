.shop__collection-nav {
    margin-right: 2.25rem;
}

.shop__collection-nav__list {
    list-style: none;
}

.shop__collection-nav__list__item {
    white-space: nowrap;
    margin-bottom: .75rem;
}

.shop__collection-nav__list__link {
    display: block;
    padding-right: 1rem;
    text-decoration: none;
    color: var(--med-warm-grey);
}

.shop__collection-nav__list__link.active {
    color: var(--base-font-dark-clr);
}

/* Admin Link Styling */
.admin-add-product-link {
    display: inline-block;
    margin-bottom: 1rem;
    padding: .5rem .75rem;
    background-color: var(--light-green);
    color: var(--very-dark-emerald-green);
    text-decoration: none;
}