.shop__hero {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin-bottom: 4.5rem;
}

@media screen and (max-width: 68.75em) {
    .shop__hero {
        display: none;
    }
}