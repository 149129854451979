.about__hero {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(5, 1fr);

    /* Image */
    background-image: url("https://res.cloudinary.com/dev-project/image/upload/f_auto,q_auto:best/v1625683324/evergreen-app/room-with-plants_efygzq.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.about__hero__card {
    grid-column: 3 / 5;
    grid-row: 2 / 3;

    display: inline-block;
    padding: 2.5rem 3rem;
    background-color: hsla(0, 0%, 100%, .32);
}

.about__hero__card__title {
    margin-bottom: .25rem;
    font-size: var(--fz-xl);
}

.about__hero__card__desc {
    font-size: var(--fz-md);
}

@media screen and (max-width: 68em) {
    .about__hero {
        display: block;
    }

    .about__hero__card {
        width: 100%;
        background-color: hsla(0, 0%, 100%, .6);
    }
}