.home__collection-card__inner-container {
    width: 100%;
    max-width: var(--base-container-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 18rem;
}

.home__collection-card__fig {
    border-radius: 50%;
}

.home__collection-card:nth-of-type(odd) {
    padding: 9rem 0;
}

.home__collection-card:nth-of-type(even) {
    padding: 9rem 0;
    background-color: var(--light-warm-grey);
}

.home__collection-card:nth-of-type(even) .home__collection-card__article {
    order: -1;
}

.home__collection-card__fig__img {
    display: block;
    width: 20rem;
    height: 20rem;
    border: none;
    border-radius: 50%;
    object-fit: cover;
}

.home__collection-card__article__badge {
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border: none;
    background-color: var(--cta-accent-clr);
    color: var(--cta-accent-clr-dark);
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
}

.home__collection-card__article__title {
    margin-bottom: .5rem;
}

.home__collection-card__article__desc {
    margin-bottom: 1.5rem;
}

.home__collection-card__article__cta-btn-link-wrapper {
    text-decoration: none;
}

.home__collection-card__article__cta-btn {
    display: flex;
    align-items: center;
    gap: .75rem;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    border: none;
    cursor: pointer;
    background: none;
}

.home__collection-card__article__cta-btn__span {
    color: var(--cta-accent-clr-dark);
}

@media screen and (max-width: 83em) {
    .home__collection-card.home__collection-card--narrow {
        padding: 6rem 1.5rem;
    }

    .home__collection-card__inner-container {
        gap: 10rem;
    }
}

@media screen and (max-width: 55em) {
    .home__collection-card.home__collection-card--narrow {
        padding: 4rem 1.5rem;
    }

    .home__collection-card__inner-container {
        gap: 4rem;
    }
}

@media screen and (max-width: 44em) {
    .home__plant-types-inner-wrapper {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .home__plant-types__column--left,
    .home__plant-types__column--right {
        padding: 4rem 1.5rem;
    }

    .home__collection-card__inner-container {
        flex-direction: column;
        gap: 3rem;
    }

    .home__collection-card__fig {
        order: -1;
    }

    .home__collection-card__fig__img {
        width: 15rem;
        height: 15rem;
    }
}