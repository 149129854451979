.form-segment {
    padding: 2rem;
    margin-bottom: 1rem;
    /* Grid Properties */
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    align-items: start;

    background-color: var(--light-warm-grey);
}

@media screen and (max-width: 65.625em) {
    .form-segment {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}