.cart-table {
    margin-bottom: 4rem;
    border-collapse: collapse;
}

.cart-table__thead__tr {
    text-align: left;
    font-weight: 700;
    color: var(--med-warm-grey);
    border-bottom: 1px solid var(--light-warm-grey);
}

.cart-table__thead__tr__th {
    padding: 2rem 3rem;
}

/* Data cells */
.cart-table__tbody__tr__td {
    padding: 1.25rem 3rem;
}

.cart-table__prod-info {
    display: flex;
    align-items: center;
    gap: 3rem;
    padding-right: 8rem;
    padding-left: 0;
}

.cart-table__thead__product {
    padding-left: 0;
}

.cart-table__prod-info__title {
    margin-bottom: .5rem;
    font-size: var(--fz-md);
}

.cart-table__qty-container__btn {
    padding: .75rem;
    border: none;
    background: none;
    cursor: pointer;
}

.cart-table__tbody__tr__td__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;

    background: none;
    border: none;
    cursor: pointer;
}

.x-icon {
    width: 1.5rem;
    stroke: var(--light-med-warm-grey);
}

.total-info {
    display: grid;
    justify-items: center;
}

.total-info__group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: .5rem;
    column-gap: 2rem;
}

.total-info__group__span {
    text-align: left;
}

.total-info__group__span-val {
    text-align: right;
}

.total-info__group__span--total,
.total-info__group__span-val--total {
    font-weight: 700;
}

.total-info__group__span-val--total {
    color: var(--cta-accent-clr-dark);
}

@media screen and (max-width: 83.125em) {
    .cart-table {
        margin: 0 1.5rem 4rem 1.5rem;
    }

    .cart-table__thead__tr__th {
        padding: 1rem 1.5rem;
    }

    .cart-table__thead__product {
        padding-left: 0;
    }

    .cart-table__tbody__tr__td {
        padding: 1rem 1.5rem;
    }

    .cart-table__prod-info {
        padding-left: 0;
    }
}

@media screen and (max-width: 47.5em) {
    .cart-table__thead__tr__th {
        padding: .5rem 0;
    }

    .cart-table__thead__product {
        padding-left: 0;
    }

    .cart-table__tbody__tr__td {
        padding: .5rem 1rem;
    }

    .cart-table__prod-info {
        padding-left: 0;
        gap: 1rem;
    }
}

@media screen and (max-width: 40em) {
    /* Horizontally scrollable table */
    .cart-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;

        position: relative;
    }

    /* Horizontal fade overlay */
    .cart-table::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to right, hsla(0, 0%, 100%, 0) 75%, hsla(0, 0%, 100%, .9)) 100%;
        z-index: 1;
    }

    /* Disable fade class added in JS when user scrolls */
    .cart-table--disable-fade::before {
        display: none;
    }

    .total-info {
        margin: 0 1.5rem 4rem 1.5rem;
        justify-items: stretch;
    }
}