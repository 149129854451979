.add-product__form__column--left {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.form-group__label {
    margin-bottom: .5rem;
    font-size: var(--fz-md);
}

.form-group__input,
.form-group__select {
    max-width: 14rem;
    padding: .5rem;
    border: none;
    font-size: 1rem;
}

.form-group__textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    line-height: 1.6;
    max-width: 25rem;
    width: 100%;
}

.add-product__form__sizes-group:not(:last-of-type) {
    margin-bottom: 3rem;
}

.add-product__form__sub-heading {
    margin-bottom: 1.25rem;
}

.add-product__form__file-input {
    margin-bottom: 1.5rem;
}

.add-product__form__submit-btn {
    padding: 1rem 3rem;
    background-color: var(--cta-accent-clr);
    border: none;
    font-size: var(--fz-md);
    font-weight: 700;
    color: var(--cta-accent-clr-dark);
    cursor: pointer;
}

@media screen and (max-width: 30em) {
    .add-product__form__sub-title {
        font-size: var(--fz-md);
    }

    .add-product__form__sub-heading {
        font-size: 1rem;
    }

    .form-group__label {
        font-size: 1rem;
    }

    .form-group__textarea {
        max-width: 14rem;
    }
}