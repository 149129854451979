.header {
    margin: 0 1.5rem;
    padding: 2.5rem 0;
    display: flex;
    justify-content: center;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: var(--base-container-width);
}

.nav__standard-links-list,
.nav__right-group,
.nav__links-list--auth {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.nav__standard-links-list,
.nav__links-list--auth {
    list-style: none;
}

.nav__link,
.nav__logo-link,
.nav__links-list__link {
    text-decoration: none;
}

.nav__logo-link {
    font-size: var(--fz-lg);
    font-weight: 700;
}

/* Header Styling Specific to Home Page */
.header--home {
    grid-column: 1 / -1;
    align-self: start;
}

@media screen and (max-width: 40em) {
    .nav {
        flex-direction: column;
        align-items: start;
        gap: .5rem;
    }

    .nav__logo-link {
        order: -1;
    }
}
