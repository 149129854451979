.title-bar {
    padding: 1.25rem 0;
    border-top: 1px solid var(--light-warm-grey);
    border-bottom: 1px solid var(--light-warm-grey);
    line-height: 1;
    text-transform: capitalize;
}

@media screen and (max-width: 83.125em) {
    .title-bar {
        margin: 0 1.5rem;
    }
}