.shop__products-section__card__delete-btn {
    margin-top: .5rem;
    padding: .25rem 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: .08em;
    color: var(--cta-accent-clr-dark);
    border: none;
    background: none;
    cursor: pointer;
}