.home-hero__section {
    grid-column: 2 / 3;
    width: var(--base-container-width);
    display: flex;
    justify-content: flex-end;
}

.home-hero__title__span {
    display: block;
    font-size: 3.5rem;
}

.arrow-icon--down {
    display: block;
    margin-left: auto;
}

@media screen and (max-width: 83em) {
    .home-hero__section {
        width: 100%;
        max-width: var(--base-container-width);
        padding-bottom: 2rem;
        grid-row: 3 / 4;
    }
}