.shop-inner-wrapper {
    padding: 3rem 0;
    max-width: 90%;
    display: flex;
}

@media screen and (max-width: 83.125em) {
    .shop-inner-wrapper {
        margin: 0 1.5rem;
        max-width: 100%;
    }
}

@media screen and (max-width: 46.875em) {
    .shop-inner-wrapper {
        flex-direction: column;
        gap: 1rem;
    }
}