.shop__products-section__title {
    margin-bottom: 1rem;
}

.shop__products-section__product-card-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

@media screen and (max-width: 68.75em) {
    .shop__products-section__product-card-grid-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 33.125em) {
    .shop__products-section__product-card-grid-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}