.order {
    margin-bottom: 1rem;
    padding: 2.5rem 4rem;
    max-width: 55rem;

    background-color: var(--light-warm-grey);
}

.order__descriptor-group {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 39em) {
    .order {
        padding: 2rem 2.5rem;
    }
}

@media screen and (max-width: 34.375em) {
    .order {
        padding: 1rem 1.5rem;
    }
}