.footer {
    background-color: var(--light-warm-grey);
}

.footer-section-wrapper {
    margin: 0 auto;
    padding: 4rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8rem;
    max-width: var(--base-container-width);
}

.footer__container--left,
.footer__container--right {
    padding: 2rem 0;
}

.footer__container--left {
    border-right: 1px solid #d6d3d1;
}

.footer__logo-title {
    margin-bottom: 1rem;
}

.footer__creation-attribution {
    font-size: var(--fz-sm);
}

.footer__container--right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.footer__title {
    margin-bottom: .5rem;
}

.footer__container--right__column--left__group {
    margin-bottom: 3rem;
}

.footer__link {
    display: block;
    margin-bottom: 1rem;
    text-decoration: none;
}

.footer__link:hover {
    color: var(--base-font-dark-clr);
}

.footer__creation-attribution,
.footer__link {
    color: var(--med-warm-grey);
}

@media screen and (max-width: 83.125em) {
    .footer {
        padding: 0 1.5rem;
    }
}

@media screen and (max-width: 50em) {
    .footer__container--right {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 31.25em) {
    .footer-section-wrapper {
        grid-template-columns: 1fr;
        gap: 4rem;
    }

    .footer__container--right {
        order: -1;
    }

    .footer__container--left {
        border-right: none;
        border-top: 1px solid #d6d3d1;
    }
}