.add-product-inner-wrapper {
    padding: 3rem 0;
    width: 90%;
    display: flex;
}

.add-product-inner-wrapper__right {
    flex-grow: 1;
}

@media screen and (max-width: 65.625em) {
    .add-product-inner-wrapper {
        max-width: 100%;
        width: 100%;
        padding: 3rem 1.5rem;
    }
}

@media screen and (max-width: 38.125em) {
    .add-product-inner-wrapper {
        flex-direction: column;
        gap: 1rem;
    }
}