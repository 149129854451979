.shop__products-section__link {
    display: block;
    text-decoration: none;
}

.shop__products-section__card__fig {
    margin-bottom: .75rem;
}

.shop__products-section__card__img {
    display: block;
    width: 100%;
    height: auto;
}

.shop__products-section__card__content-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shop__products-section__card__title,
.shop__products-section__card__price {
    font-size: var(--fz-sm);
}

.shop__products-section__card__title {
    font-weight: 400;
}

@media screen and (max-width: 68.75em) {
    .shop__products-section__card__content-group {
        flex-direction: column;
        align-items: start;
        gap: .25rem;
    }
}