.order-item {
    padding: 1.25rem 1rem;
    background-color: #fff;
}

.order-item:not(:last-of-type) {
    margin-bottom: .5rem;
}

.order-item__inner-container {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.order-item__fig {
    width: 8rem;
}

.order-item__fig__img {
    width: 100%;
    height: auto;
}

/* Card grid layout */
.order-item__content {
    display: grid;
    grid-template-areas:
        "title    title    title"
        "category category category"
        "size-h   price-h  qty-h"
        "size     price    qty";
    column-gap: 2.5rem;
}

.order-item__content__title {
    grid-area: title;
    margin-bottom: .5rem;
}

.order-item__content__category-chip-container {
    grid-area: category;
    margin-bottom: 1rem;
}

.order-item__content__category-chip {
    display: inline-block;
    padding: .5rem .75rem;
    background-color: var(--light-warm-grey);
    border-radius: 500px;

    line-height: 1;
    text-transform: capitalize;
    font-size: var(--fz-sm);
}

.order-item__content__sub-heading {
    margin-bottom: .5rem;
    color: var(--light-med-warm-grey);
}

.order-item__content__sub-heading--size {
    grid-area: size-h;
}

.order-item__content__sub-heading--price {
    grid-area: price-h;
}

.order-item__content__sub-heading--qty {
    grid-area: qty-h;
}

.order-item__content__desc {
    line-height: 1;
    color: var(--med-warm-grey);
}

.order-item__content__size {
    grid-area: size;
}

.order-item__content__price {
    grid-area: price;
}

.order-item__content__qty {
    grid-area: qty;
}

.order-item__content__qty__x {
    display: inline-block;
    margin-right: .25rem;
    font-size: var(--fz-sm);
}

@media screen and (max-width: 39em) {
    .order-item__content {
        column-gap: 1.5rem;
    }
}

@media screen and (max-width: 34.375em) {
    .order__descriptor-group {
        gap: 1.5rem;
    }

    .order__descriptor {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .order__descriptor__heading {
        font-size: var(--fz-md);
    }

    .order__descriptor__data-segment {
        font-size: var(--fz-sm);
    }

    .order-item__content {
        grid-template-areas:
            "title"
            "size-h"
            "size"
            "price-h"
            "price"
            "qty-h"
            "qty";
    }

    .order-item__content__sub-heading {
        margin-bottom: .25rem;
    }

    .order-item__content__desc:not(:last-of-type) {
        margin-bottom: 1.5rem;
    }

    .order-item__content__title {
        font-size: var(--fz-md);
    }

    /* Remove chip on small screens */
    .order-item__content__category-chip-container {
        display: none;
    }

    .order-item__content__sub-heading,
    .order-item__content__desc {
        font-size: var(--fz-sm);
    }

    .order-item__inner-container {
        gap: 1rem;
    }
}

@media screen and (max-width: 22.5em) {
    .order-item__inner-container {
        flex-direction: column;
        align-items: start;
    }
}