.about-grid-wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
}


@media screen and (max-width: 85em) {
    .about-grid-wrapper {
        min-height: revert;
        display: block;
    }
}