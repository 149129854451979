.about__features {
    width: 100%;
    max-width: var(--base-container-width);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2.5rem;
    align-items: center;
    padding: 4rem 0;
}

.about__features__fig {
    max-width: 35rem;
}

.about__features__fig__img {
    display: block;
    width: 100%;
    height: auto;
}

.about__features__article {
    padding: 3rem 4rem;
}

.about__features__article__title {
    margin-bottom: .75rem;
}

.about__features__article__desc {
    max-width: 68ch;
}

.about__features__video-container {
    grid-column: 1 / -1;
}

.about__features__video {
    display: block;
    width: 100%;
    height: auto;
}

/* Line up with right side of wrapper */
.about__features__fig--right {
    justify-self: end;
}

.about__features__fig--span-all {
    grid-column: 1 / -1;
    width: 100%;
    max-width: 100%;
}

@media screen and (max-width: 46.25em) {
    .about__features {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 27em) {
    .about__mission-section {
        padding: 4rem 1.5rem;
    }

    .about__mission-section__desc {
        font-size: var(--fz-md);
    }

    .about__features__article {
        padding: 1rem 1.5rem;
    }
}