.auth-card {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    min-height: 70vh;

    box-shadow: 0 8px 14px hsla(0, 0%, 0%, .15);
}

.auth-card__content {
    padding: 3rem 6rem;
}

.auth-card__content__title {
    margin-bottom: 2.5rem;
}

.auth-card__content__instructions {
    margin-bottom: 2rem;
    color: var(--med-warm-grey);
}

@media screen and (max-width: 51.25em) {
    .auth-card {
        grid-template-columns: 1fr;
        min-height: 50vh;
    }
}

@media screen and (max-width: 27em) {
    .auth-card__content {
        padding: 1rem 1.5rem;
    }
}