.about__mission-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9rem 0;
    background-color: var(--light-warm-grey);
}

.about__mission-section__desc {
    max-width: 70ch;
    font-size: var(--fz-lg);
}