.auth-card__content__form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.auth-card__content__form__label {
    margin-bottom: .5rem;
}

.auth-card__content__form__input {
    padding: .5rem;
    border: none;
    background-color: var(--light-warm-grey);
    font-size: 1rem;
}

.auth-card__content__form__submit-btn {
    margin-bottom: 1rem;

    width: 100%;
    padding: .75rem 1.5rem;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 700;
    font-size: 1rem;
    background-color: var(--cta-accent-clr);
    border: none;
    cursor: pointer;
}

.auth-card__content__form__link {
    display: block;
    margin-bottom: 1rem;
}