.checkout-link {
    display: inline-block;
    grid-column: 1 / -1;
    padding: 1rem 1.5rem;
    margin-top: 1rem;

    border: none;
    cursor: pointer;
    background-color: var(--cta-accent-clr);
    color: var(--cta-accent-clr-dark);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    text-align: center;
    text-decoration: none;
}