.product__grid-wrapper {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 3rem;
}

.product__fig__img {
    display: block;
    width: 100%;
    height: auto;
}

.product__content__heading-container {
    display: flex;
    align-items: baseline;
    gap: 4rem;
    padding-bottom: 1.25rem;
    margin-bottom: 1.5rem;
    line-height: 1;

    border-bottom: 1px solid var(--light-warm-grey);
}

.product__content__size-title {
    margin-bottom: 1rem;
}

.product__content__starting-price-para {
    font-size: var(--fz-sm);
}

.product__content__starting-price-para__price {
    display: inline-block;
    margin-left: .5rem;
    font-size: var(--fz-md);
}

.product__content__add-info-container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
}

.product__content__add-btn {
    padding: 1.25rem 3rem;
    border: none;
    background-color: var(--cta-accent-clr);
    color: var(--cta-accent-clr-dark);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    cursor: pointer;
    font-size: var(--fz-sm);
}

.product__content__desc {
    margin-bottom: 2rem;
}

.product__content__info-article-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.product__content__info-article {
    padding: 2rem 4rem;
    background-color: var(--light-warm-grey);
    text-align: center;
}

.product__content__shipping-info {
    position: relative;
}

.product__content__shipping-info__desc,
.product__content__warranty-info__desc {
    margin-bottom: 1rem;
}

/* Border */
.product__content__shipping-info::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    background-color: var(--light-med-warm-grey);
    width: 1px;
    height: 65%;
}

/* SVG Icons */
.product__box-icon {
    width: 3.5rem;
    height: 3.5rem;
    fill: var(--med-warm-grey);
}

.product__content__selected-price {
    font-weight: 700;
    font-size: var(--fz-md);
}

@media screen and (max-width: 83.125em) {
    .product__grid-wrapper {
        margin: 0 1.5rem;
    }
}

@media screen and (max-width: 62.5em) {
    .product__content__info-article-container {
        grid-template-columns: 1fr;
    }

    /* Remove border */
    .product__content__shipping-info::after {
        display: none;
    }
}

@media screen and (max-width: 45em) {
    .product__grid-wrapper {
        grid-template-columns: 1fr;
    }

    .product__fig {
        max-width: 20rem;
    }

    .product__content__add-btn {
        padding: 1rem 1.5rem;
    }

    .product__content__selected-price {
        font-size: var(--fz-sm);
    }
}