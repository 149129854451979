.product__content__size-btn {
    margin-bottom: 4rem;

    border: none;
    background: none;
    cursor: pointer;
}

.product__content__size-btn:not(:last-of-type) {
    margin-right: 2rem;
}

.product__content__size-btn__gallon-size {
    padding: 1rem;
    margin-bottom: .75rem;
    background-color: var(--light-warm-grey);
    font-size: 1rem;
}

.product__content__size-btn__price {
    font-size: 1rem;
}

@media screen and (max-width: 26.25em) {
    .product__content__size-btn {
        margin-bottom: 1.5rem;
    }

    .product__content__size-btn:not(:last-of-type) {
        margin-right: 1rem;
    }
}