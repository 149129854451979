.nav__cart-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: .5rem 1rem;
    text-decoration: none;

    line-height: 1;
    border-radius: 500px;
    border: none;
    cursor: pointer;
    background-color: var(--cta-accent-clr);
    font-size: 1rem;
}

.cart-icon {
    width: 1.25rem;
    stroke: var(--base-font-dark-clr);
}